<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Management" />
      <b-breadcrumb-item text="Forms" :to="{ name: 'management-forms' }" />
      <b-breadcrumb-item :text="form.name" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-form">
        <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateForm">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-form">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateState">
          <font-awesome-icon :icon="['fas', form.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
          <span class="align-middle ml-50">{{ form.state.enabled ? 'Disable' : 'Enable' }}</span>
        </b-dropdown-item>
        <can do="delete" on="management-form">
          <b-dropdown-item @click="$refs.layout.confirmDelete(form, deleteForm, cascadeConfirmDeleteOptions)">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider></b-dropdown-divider>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <validation-observer ref="observer" tag="div">
        <form ref="form">
          <b-row>
            <b-col cols="8">
              <b-card>
                <b-row>
                  <b-col align-self="start" cols="auto">
                    <b-avatar v-if="hasCustomAvatar(form.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white" :disabled="!state.editing">
                      <b-img fluid fluid-grow :src="form.avatar.src" :alt="form.avatar.name"></b-img>
                      <template #badge>
                        <b-icon :icon="enabledIcon(form.state.enabled)" :variant="enabledIconVariant(form.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                      <template #badge>
                        <b-icon :icon="enabledIcon(form.state.enabled)" :variant="enabledIconVariant(form.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <avatar-modal v-if="state.editing"
                                  title="Form Avatar"
                                  :avatar="form.avatar"
                                  @update-avatar="updateAvatar"/>
                  </b-col>
                  <b-col align-self="center">
                    <b-row>
                      <b-col cols="12">
                        <validation-provider v-slot="validationContext" vid="name" name="Form Name" rules="required">
                          <b-form-group label="Form Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                            <b-input v-model="form.name" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12">
                        <validation-provider v-slot="validationContext" vid="type" name="Type" rules="required">
                          <b-form-group label="Type (determines application questions)" label-for="form-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                            <v-select id="form-input"
                                      v-model="form.type"
                                      :disabled="!state.editing"
                                      :loading="state.loading"
                                      label="text"
                                      :reduce="type => type.value"
                                      :options="options.types"
                                      :select-on-tab="true"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      placeholder="Type"
                                      class="form-control-plaintext w-100"/>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card no-body>
                <b-card-header>
                  <h6>Instruments</h6>
                </b-card-header>
                <template v-if="form.instruments.items.length > 0">
                  <b-list-group flush>
                    <b-list-group-item v-for="instrument in form.instruments.items"
                                       :key="instrument.id"

                                       class="d-flex justify-content-between align-items-center">
                      <div>
                        <b-avatar variant="light-primary" class="mr-1">
                          <font-awesome-icon icon="fas fa-music"></font-awesome-icon>
                        </b-avatar>
                        {{ instrument.name }}
                      </div>

                      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" dropleft toggle-class="pr-0" boundary="window" :popper-opts="{ positionFixed: true }">
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <b-dropdown-item :to="{ name: 'management-instrument', params: { id: instrument.id, slug: instrument.slug }}">
                          <feather-icon icon="FileTextIcon" />
                          <span class="align-middle ml-50">View</span>
                        </b-dropdown-item>
                        <can do="update" on="management-instruments">
                          <b-dropdown-divider/>
                          <b-dropdown-item @click="removeInstrument(instrument.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                          </b-dropdown-item>
                        </can>
                      </b-dropdown>

                    </b-list-group-item>
                  </b-list-group>
                </template>
                <template v-else>
                  <b-card-body>
                    Form Currently Has No Instruments
                  </b-card-body>

                </template>
              </b-card>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </template>
    <template #debug>
      <debug title="Form">{{ form }}</debug>
    </template>
  </page-layout>
</template>


<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import slugify from 'slugify';
import vSelect from 'vue-select'
import avatar from '@/mixins/avatar.mixin'
import notify from '@/mixins/notify.mixin'
import status from '@/mixins/status.mixin'
import role from '@/mixins/role.mixin';
import print from '@/mixins/print.mixin';
import { API, graphqlOperation } from 'aws-amplify';
import { getForm, getFormBySlug} from '@/graphql/queries/form';
import {deleteForm, updateForm, updateInstrument} from '@/graphql/mutations';
import { cascadeDeleteForm, cascadeConfirmDeleteOptions} from '@/graphql/cascade/form';

export default {
  components: {
    PageLayout,
    AvatarModal,
    vSelect
  },
  mixins: [ avatar, role, status, print, notify ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: null,
      icon: 'fa-solid fa-file-lines',
      options: {
        types: [
          { text: 'Jazz - Instrumental', value: 'jazzInstrumental' },
          { text: 'Jazz - Vocal', value: 'jazzVocal' },
          { text: 'Strings', value: 'strings' },
          { text: 'Voice', value: 'voice' },
          { text: 'Wind And  Percussion', value: 'windAndPercussion' },
          { text: 'Other', value: 'other' }
        ]
      },
      cascadeConfirmDeleteOptions
    }
  },
  async mounted() {
    await this.getForm();
  },
  methods: {
    async getForm() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getForm, { id: this.id }));
        this.form = response.data.getForm
      }
      else {
        const response = await API.graphql(graphqlOperation(getFormBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.form = response.data.getFormBySlug.items[0]
      }
      this.form.instruments.items.sort((a, b) => a.name.localeCompare(b.name))
      this.$refs.layout.state.loading = false
    },
    async patchForm(input) {
      try {
        const response = await API.graphql(graphqlOperation(updateForm, { input: input } ));
        this.zone = response.data.updateForm;
        this.notify({ title: 'Success', text: 'Form was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Form failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchForm({
          id: this.form.id,
          name: this.form.name,
          type: this.form.type,
          slug: slugify(this.form.name, { lower: true }),
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'Form failed to update. Missing required fields.', icon: this.icon, variant: 'warning'});
      }
    },
    async deleteForm(form, swalCallback) {
      try {
        await this.cascadeDeleteForm(form.id, swalCallback)
        await this.notify({ title: 'Success', text: 'Form was successfully updated', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'management-forms' })
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Form failed to update', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteForm,

    async patchInstrument(input) {
      try {
        await API.graphql(graphqlOperation(updateInstrument, { input: input } ));
        this.notify({ title: 'Success', text: 'Instrument was successfully removed', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Failed to remove instrument', icon: this.icon, variant: 'danger'});
      }
    },
    async removeInstrument(id) {
      await this.patchInstrument({ id: id, formInstrumentsId: null }).then(() => {
        this.form.instruments.items = this.form.instruments.items.filter(instrument => instrument.id !== id)
      })
    },

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.form.avatar = avatar;
      await this.patchForm({ id: this.form.id, avatar: this.form.avatar })
    },
    async updateState() {
      this.form.state.enabled = !this.form.state.enabled;
      await this.patchForm({ id: this.form.id, state: this.form.state })
    },
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.getForm();
      this.$refs.layout.state.loading = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  }
}
</script>

<style lang="scss">
.form-control-plaintext {
  border: 0!important;
  border-radius: 0!important;
  -webkit-box-shadow: unset!important;
  box-shadow: unset!important;
  padding: 0!important;
  margin: 0!important;
  transition: unset!important;
  height: unset!important;
  /*font-size: inherit!important;
  font-weight: inherit!important;*/
}

.form-control-plaintext[readonly] {
  background-color: unset!important;
  opacity: 1;
}

.form-control-plaintext:focus-visible {
  outline: 0;
}
.form-control-plaintext:focus {
  color: inherit;
  background-color: inherit;
  border: 0;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
</style>
