export const getForm = /* GraphQL */ `
    query GetForm($id: ID!) {
        getForm(id: $id) {
            id
            slug
            name
            type
            instruments {
                items {
                    id
                    slug
                    name
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;

export const getFormBySlug = /* GraphQL */ `
    query GetFormBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getFormBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                name
                type
                instruments {
                    items {
                        id
                        slug
                        name
                    }
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
        }
    }
`;

